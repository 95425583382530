'use client';
import React from 'react';

import Modal from 'features/modal/components/Modal/Modal';
import SidebarHeader from 'features/sidebar/components/SidebarHeader/SidebarHeader';
import SidebarLink from 'features/sidebar/components/SidebarLink/SidebarLink';
import SidebarSettings from 'features/sidebar/components/SidebarSettings/SidebarSettings';
import Authorize from 'features/auth/components/Auth/Auth';
import {SidebarModalCss, SidebarHeaderLink, SidebarContainer} from './styles';
import {selectCurrentUser, selectCurrentUserPermissions} from 'features/users/selectors';
import {logoutUser} from 'features/auth/actions';
import {hideModal} from 'features/modal/slice';
import {useParams, useRouter} from 'next/navigation';
import {useDispatch, useSelector} from 'react-redux';
import IconHomeOutline from 'features/icons/IconHomeOutline';
import IconLocationOutline from 'features/icons/IconLocationOutline';
import IconMessageCheck from 'features/icons/IconMessageCheck';
import IconClipboard from 'features/icons/IconClipboard';
import IconCreditCard from 'features/icons/IconCreditCard';
import IconQuestionCircleOutline from 'features/icons/IconQuestionCircleOutline';
import IconSettingsOutline from 'features/icons/IconSettingsOutline';
import Logo from 'assets/img/logo.svg';
import IconSearch from 'features/icons/IconSearch';
import {selectAccountByIdNoWithRouter} from 'features/accounts/selectors';

const Sidebar = (props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const currentAccountId = useSelector((state) => state.accounts.currentAccountId);
    let accountId = currentAccountId;
    const user = useSelector(selectCurrentUser);
    const userPermissions = useSelector(selectCurrentUserPermissions);
    const modalType = useSelector((state) => state.modal.type);
    const userAccountType = useSelector((state) => state.accounts.currentAccountType);
    const isMarketingAccount = userAccountType === 'marketing' && params?.accountId;
    if (isMarketingAccount) {
        accountId = params?.accountId;
    }
    const primaryAccount = useSelector((state) =>
        selectAccountByIdNoWithRouter(state, currentAccountId),
    );

    const router = useRouter();

    const handleLogout = () => {
        dispatch(logoutUser());

        router.push('/login');
    };

    const toggleSidebar = () => {
        if (modalType === 'SIDEBAR') {
            dispatch(hideModal());
        }
    };

    /**
     * Set sidebar active state (used for mobile)
     */
    const sidebarActive = modalType === 'SIDEBAR';
    const userId = user?.user_id;

    return (
        <Modal
            $css={SidebarModalCss}
            mousedown
            sidebarActive={sidebarActive}
            handleClose={toggleSidebar}>
            <SidebarContainer>
                <SidebarHeaderLink href="/dashboard">
                    <Logo />
                </SidebarHeaderLink>
                <SidebarHeader
                    user={user}
                    primaryAccount={primaryAccount}
                    accountId={accountId}
                />
                <div onClick={() => dispatch(hideModal())}>
                    <SidebarLink
                        text="Overview"
                        href={
                            isMarketingAccount
                                ? `/account/${accountId}/dashboard`
                                : '/dashboard'
                        }
                        alternativeHref="/"
                        Icon={IconHomeOutline}
                    />
                    <SidebarLink
                        text="Listings"
                        href={`/account/${accountId}/listings`}
                        Icon={IconLocationOutline}
                    />
                    <Authorize permissions={['listing-view-all']}>
                        <SidebarLink
                            text="Find Listings"
                            href="/listings"
                            Icon={IconSearch}
                        />
                    </Authorize>
                    <Authorize
                        authorize={(user) =>
                            user.permissions.includes('review-view-own') ||
                            user.permissions.includes('review-view-all') ||
                            user.permissions.includes('review-view-publishedReviews')
                        }>
                        <SidebarLink
                            text="Reviews"
                            href={
                                userPermissions.includes('review-view-own') &&
                                !userPermissions.includes('review-view-all')
                                    ? `/account/${accountId}/reviews`
                                    : '/reviews'
                            }
                            Icon={IconMessageCheck}
                        />
                    </Authorize>
                    <Authorize permissions={['account-view-all']}>
                        <SidebarLink
                            text="Reports"
                            href="/reports"
                            Icon={IconClipboard}
                        />
                    </Authorize>
                    <SidebarLink
                        text="Billing"
                        href={`/account/${accountId}/billing`}
                        Icon={IconCreditCard}
                    />
                    <SidebarLink
                        text="Support"
                        href="/support"
                        Icon={IconQuestionCircleOutline}
                    />
                </div>
                <SidebarSettings
                    text="Settings"
                    Icon={IconSettingsOutline}
                    userAccountType={userAccountType}
                    accountId={accountId}
                    userId={userId}
                    userPermissions={userPermissions}
                    handleLogout={handleLogout}
                    onClick={() => dispatch(hideModal())}
                />
            </SidebarContainer>
        </Modal>
    );
};

export default Sidebar;
